import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { enUS, ru } from 'date-fns/locale';

import { LocaleEnum } from './enums/locale.enum';
import { LocaleInterceptor } from './services/locale.interceptor';
import { LocaleService } from './services/locale.service';
import { LocaleState } from './services/locale.state';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([LocaleState]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) => localeService.getCurrentLocale(),
      deps: [LocaleService],
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (localeService: LocaleService) => (localeService.getCurrentLocale() === LocaleEnum.Ru ? ru : enUS),
      deps: [LocaleService],
    },
  ],
})
export class LocaleModule {}
